import { FC } from 'react';
import IconProps from './IconProps';

const InventoryIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  const attr = { onClick: onClick, className: `inline ${cursor} ${className}` };

  if (gradient) {
    return (
      <svg {...attr} viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.2732 3.56592C17.323 3.56592 16.5527 4.33616 16.5527 5.28636C16.5527 6.23651 17.323 7.00681 18.2732 7.00681H29.0172C29.9673 7.00681 30.7376 6.23651 30.7376 5.28636C30.7376 4.33616 29.9673 3.56592 29.0172 3.56592H18.2732ZM18.2732 16.1825C17.323 16.1825 16.5527 16.9528 16.5527 17.9029C16.5527 18.8531 17.323 19.6234 18.2732 19.6234H29.0172C29.9673 19.6234 30.7376 18.8531 30.7376 17.9029C30.7376 16.9528 29.9673 16.1825 29.0172 16.1825H18.2732ZM18.2732 28.7991C17.323 28.7991 16.5527 29.5694 16.5527 30.5196C16.5527 31.4698 17.323 32.24 18.2732 32.24H29.0172C29.9673 32.24 30.7376 31.4698 30.7376 30.5196C30.7376 29.5694 29.9673 28.7991 29.0172 28.7991H18.2732Z"
          fill="#5A5766"
        />
        <path
          d="M9.76268 0.886673C10.0433 0.538929 10.4482 0.314 10.8917 0.259462C11.2374 0.214541 11.5886 0.275698 11.8988 0.434825C12.209 0.593952 12.4635 0.843549 12.6287 1.15056C12.7938 1.45756 12.8618 1.80749 12.8237 2.15401C12.7855 2.50053 12.643 2.8273 12.415 3.09103L6.68022 9.97281C6.52658 10.157 6.33614 10.307 6.12111 10.4132C5.90609 10.5194 5.67122 10.5796 5.4316 10.5897C5.19197 10.5998 4.95288 10.5597 4.72966 10.472C4.50643 10.3843 4.30402 10.2509 4.13539 10.0803L0.694503 6.63944C0.5185 6.48343 0.376352 6.29297 0.276838 6.07986C0.177324 5.86675 0.122557 5.63553 0.11592 5.40042C0.109283 5.16532 0.150916 4.93136 0.23825 4.71297C0.325583 4.49459 0.456761 4.29644 0.623682 4.13074C0.790603 3.96505 0.989722 3.83532 1.20874 3.7496C1.42776 3.66388 1.66203 3.62397 1.89708 3.63234C2.13212 3.64071 2.36296 3.69717 2.57533 3.79825C2.7877 3.89934 2.97709 4.04289 3.1318 4.22003L5.22856 6.31686L9.76268 0.886673Z"
          fill="url(#paint0_linear_11092_3136)"
        />
        <path
          d="M9.76268 13.5032C10.0433 13.1555 10.4482 12.9306 10.8917 12.876C11.2374 12.8311 11.5886 12.8923 11.8988 13.0514C12.209 13.2105 12.4635 13.4601 12.6287 13.7671C12.7938 14.0741 12.8618 14.424 12.8237 14.7706C12.7855 15.1171 12.643 15.4439 12.415 15.7076L6.68022 22.5894C6.52658 22.7735 6.33614 22.9236 6.12111 23.0298C5.90609 23.136 5.67122 23.1961 5.4316 23.2062C5.19197 23.2164 4.95288 23.1763 4.72966 23.0886C4.50643 23.0009 4.30402 22.8674 4.13539 22.6969L0.694503 19.256C0.5185 19.1 0.376352 18.9096 0.276838 18.6965C0.177324 18.4833 0.122557 18.2521 0.11592 18.017C0.109283 17.7819 0.150916 17.5479 0.23825 17.3295C0.325583 17.1112 0.456761 16.913 0.623682 16.7473C0.790603 16.5816 0.989722 16.4519 1.20874 16.3662C1.42776 16.2804 1.66203 16.2405 1.89708 16.2489C2.13212 16.2573 2.36296 16.3137 2.57533 16.4148C2.7877 16.5159 2.97709 16.6594 3.1318 16.8366L5.22856 18.9334L9.76268 13.5032Z"
          fill="url(#paint1_linear_11092_3136)"
        />
        <path
          d="M9.76268 26.1199C10.0433 25.7721 10.4482 25.5472 10.8917 25.4926C11.2374 25.4477 11.5886 25.5089 11.8988 25.668C12.209 25.8271 12.4635 26.0767 12.6287 26.3837C12.7938 26.6907 12.8618 27.0406 12.8237 27.3872C12.7855 27.7337 12.643 28.0605 12.415 28.3242L6.68022 35.206C6.52658 35.3901 6.33614 35.5401 6.12111 35.6464C5.90609 35.7526 5.67122 35.8127 5.4316 35.8228C5.19197 35.833 4.95288 35.7929 4.72966 35.7052C4.50643 35.6174 4.30402 35.484 4.13539 35.3135L0.694503 31.8726C0.5185 31.7166 0.376352 31.5262 0.276838 31.313C0.177324 31.0999 0.122557 30.8687 0.11592 30.6336C0.109283 30.3985 0.150916 30.1645 0.23825 29.9461C0.325583 29.7277 0.456761 29.5296 0.623682 29.3639C0.790603 29.1982 0.989722 29.0685 1.20874 28.9828C1.42776 28.8971 1.66203 28.8572 1.89708 28.8655C2.13212 28.8739 2.36296 28.9304 2.57533 29.0314C2.7877 29.1325 2.97709 29.2761 3.1318 29.4532L5.22856 31.55L9.76268 26.1199Z"
          fill="url(#paint2_linear_11092_3136)"
        />
        <defs>
          <linearGradient id="paint0_linear_11092_3136" x1="0.115234" y1="11.109" x2="14.815" y2="13.3554" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint1_linear_11092_3136" x1="0.115234" y1="11.109" x2="14.815" y2="13.3554" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint2_linear_11092_3136" x1="0.115234" y1="11.109" x2="14.815" y2="13.3554" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg {...attr} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5435 4.02502C13.0023 4.02502 12.5635 4.46377 12.5635 5.00502C12.5635 5.54625 13.0023 5.98502 13.5435 5.98502H19.6635C20.2047 5.98502 20.6435 5.54625 20.6435 5.00502C20.6435 4.46377 20.2047 4.02502 19.6635 4.02502H13.5435ZM13.5435 11.2117C13.0023 11.2117 12.5635 11.6505 12.5635 12.1917C12.5635 12.7329 13.0023 13.1717 13.5435 13.1717H19.6635C20.2047 13.1717 20.6435 12.7329 20.6435 12.1917C20.6435 11.6505 20.2047 11.2117 19.6635 11.2117H13.5435ZM13.5435 18.3984C13.0023 18.3984 12.5635 18.8371 12.5635 19.3784C12.5635 19.9196 13.0023 20.3584 13.5435 20.3584H19.6635C20.2047 20.3584 20.6435 19.9196 20.6435 19.3784C20.6435 18.8371 20.2047 18.3984 19.6635 18.3984H13.5435Z"
        fill="currentColor"
      />
      <path
        d="M8.69558 2.49877C8.85542 2.30069 9.08605 2.17257 9.33868 2.1415C9.53561 2.11591 9.73566 2.15075 9.91234 2.24139C10.089 2.33203 10.234 2.47421 10.3281 2.64909C10.4222 2.82397 10.4609 3.02329 10.4392 3.22067C10.4175 3.41806 10.3363 3.6042 10.2064 3.75442L6.93974 7.67442C6.85223 7.77933 6.74375 7.86477 6.62127 7.92528C6.49878 7.9858 6.365 8.02004 6.2285 8.02581C6.09201 8.03158 5.95581 8.00874 5.82866 7.95878C5.70151 7.90881 5.58621 7.83282 5.49016 7.73567L3.53016 5.77567C3.4299 5.6868 3.34893 5.57831 3.29225 5.45692C3.23556 5.33553 3.20437 5.20382 3.20059 5.0699C3.19681 4.93598 3.22052 4.80271 3.27027 4.67831C3.32001 4.55392 3.39474 4.44105 3.48982 4.34666C3.5849 4.25228 3.69832 4.17838 3.82308 4.12956C3.94784 4.08073 4.08128 4.058 4.21517 4.06276C4.34906 4.06753 4.48055 4.09969 4.60151 4.15727C4.72248 4.21485 4.83036 4.29662 4.91849 4.39752L6.11285 5.59192L8.69558 2.49877Z"
        fill={accentColor}
      />
      <path
        d="M8.69558 9.68542C8.85542 9.48734 9.08605 9.35922 9.33868 9.32815C9.53561 9.30256 9.73566 9.3374 9.91234 9.42804C10.089 9.51868 10.234 9.66086 10.3281 9.83573C10.4222 10.0106 10.4609 10.2099 10.4392 10.4073C10.4175 10.6047 10.3363 10.7908 10.2064 10.9411L6.93974 14.8611C6.85223 14.966 6.74375 15.0514 6.62127 15.1119C6.49878 15.1725 6.365 15.2067 6.2285 15.2125C6.09201 15.2182 5.95581 15.1954 5.82866 15.1454C5.70151 15.0955 5.58621 15.0195 5.49016 14.9223L3.53016 12.9623C3.4299 12.8734 3.34893 12.765 3.29225 12.6436C3.23556 12.5222 3.20437 12.3905 3.20059 12.2565C3.19681 12.1226 3.22052 11.9894 3.27027 11.865C3.32001 11.7406 3.39474 11.6277 3.48982 11.5333C3.5849 11.4389 3.69832 11.365 3.82308 11.3162C3.94784 11.2674 4.08128 11.2446 4.21517 11.2494C4.34906 11.2542 4.48055 11.2863 4.60151 11.3439C4.72248 11.4015 4.83036 11.4833 4.91849 11.5842L6.11285 12.7786L8.69558 9.68542Z"
        fill={accentColor}
      />
      <path
        d="M8.69558 16.8721C8.85542 16.674 9.08605 16.5459 9.33868 16.5148C9.53561 16.4892 9.73566 16.5241 9.91234 16.6147C10.089 16.7053 10.234 16.8475 10.3281 17.0224C10.4222 17.1973 10.4609 17.3966 10.4392 17.594C10.4175 17.7914 10.3363 17.9775 10.2064 18.1277L6.93974 22.0477C6.85223 22.1526 6.74375 22.2381 6.62127 22.2986C6.49878 22.3591 6.365 22.3934 6.2285 22.3991C6.09201 22.4049 5.95581 22.3821 5.82866 22.3321C5.70151 22.2821 5.58621 22.2061 5.49016 22.109L3.53016 20.149C3.4299 20.0601 3.34893 19.9516 3.29225 19.8303C3.23556 19.7089 3.20437 19.5771 3.20059 19.4432C3.19681 19.3093 3.22052 19.176 3.27027 19.0516C3.32001 18.9272 3.39474 18.8144 3.48982 18.72C3.5849 18.6256 3.69832 18.5517 3.82308 18.5029C3.94784 18.4541 4.08128 18.4313 4.21517 18.4361C4.34906 18.4409 4.48055 18.473 4.60151 18.5306C4.72248 18.5882 4.83036 18.67 4.91849 18.7709L6.11285 19.9652L8.69558 16.8721Z"
        fill={accentColor}
      />
    </svg>
  );
};

export default InventoryIcon;
